export const TRANSLATION_ES = {
  // login
  Lets_Connect_us: 'Conectémonos nosotros',
  Lorem_Ipsum_is_simply_dummy_text_of_the_printing_and_typesetting_industry:
    'Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica',
  Login: 'Acceso',
  Login_to_your_account: 'Ingrese a su cuenta',
  Enter_your_email_and_password_to_connect:
    'Ingrese su correo electrónico y contraseña para conectarse',
  with_Jail_connect: 'con Jail conectar',
  Email_address: 'Dirección de correo electrónico',
  Password: 'Contraseña',
  Remember_me: 'Recuérdame',
  Forgot_password: 'Olvidaste la contraseña',
  Please_enter_a_valid_email: 'Por favor ingrese una dirección de correo electrónico válida',

  // forgot password
  Forgot_your_password: 'Olvidaste tu contraseña',
  Send: 'Enviar',
  Already_have_an_account: 'Ya tienes una cuenta ?',
  verification_link_will_be_send_to_your_email:
    'enlace de verificación se enviará a tu correo electrónico',

  // reset password
  Reset_Password: 'Restablecer la contraseña',
  Reset_your_password: 'Restablecer su contraseña',
  New_password: 'Nueva contraseña',
  Confirm_New_password: 'Confirmar nueva contraseña',
  Reset: 'Reinicializar',

  // dashboard
  Total_products: 'Total de productos',
  Total_inmates: 'presos totales',
  Total_earnings: 'Ganancias Totales',
  Pending_orders: 'Ordenes pendientes',
  Total_orders: 'Órdenes Totales',

  // sidebar
  Dashboard: 'Salpicadero',
  commissary: 'Economato',
  Inmates: 'el preso',
  Order_Management: 'Gestión de pedidos',
  Category_Management: 'Gestión de categorías',
  Product_Management: 'Gestión de Producto',
  My_Profile: 'mi Perfil',
  Change_Password: 'Cambia la contraseña',
  About_us: 'Sobre nosotros',
  Terms_Conditions: 'Términos y condiciones',
  Privacy_Policy: 'Política de privacidad',
  Logout: 'cerrar sesión',
  Profile: 'Perfil',

  // profile sidebar

  Notifications: 'Notificaciones',

  // edit - profile

  Edit_Profile: 'Editar perfil',
  First_Name: 'Primer nombre',
  Last_Name: 'Apellido  ',
  Phone_Number: 'Número de teléfono',
  save: 'salvar',

  // change password

  Change_your_password: 'Cambia tu contraseña',
  Current_password: 'Contraseña actual',
  Change: 'Cambiar',

  // Commissary
  Checkout: 'Echa un vistazo',
  Product_Name: 'Nombre del producto',
  Action: 'Acción',
  Updated_At: 'Actualizado a las',
  Created_At: 'Creado en',
  Price: 'Precio',
  Category: 'Categoría',
  Image_Url: 'URL de la imagen',
  Select_Category: 'Selecciona una categoría',
  Search: 'Búsqueda',
  Add_to_cart: 'Añadir a la cesta',
  view: 'vista',
  Inmate_Password: 'Contraseña_del_recluso',

  //  modal
  My_Cart: 'Mi carrito',
  No_item_is_added: 'No se ha agregado ningún elemento',
  Total_Price: 'Precio total',
  Total_Quantity: 'Cantidad total',
  View_Product: 'Ver el producto',
  View_Kiosk: 'Ver quiosco',
  Select_Inmate: 'Seleccionar recluso',
  Search_here: 'Busca aquí',
  Select_here: 'Selecciona aquí',
  payment: 'pago',
  wallet: 'billetera',
  product_cart: 'carrito de productos',
  Add_Credit_to_your_inmate_account: 'Agregar crédito a su cuenta de recluso',
  Enter_amount: 'Ingrese la cantidad',
  Proceed_for_Payment: 'Proceed_for_Payment',
  Select_payment_method: 'Seleccionar método de pago',
  Standard_Plan: 'Plan Estándar',
  Basic_Plan: 'Plan Básico',
  // notification
  Total_Count: 'Cuenta total',

  // Inmates
  Name: 'Nombre',
  Email: 'Correo electrónico',
  Prison: 'Prisión',
  add_to_wallet: 'Agregar a la billetera',
  recharge: 'Recargar',
  communication_recharge: 'Recarga de comunicación',
  credit_inmate: 'Preso de crédito',

  // view-content
  OK: 'OK',
};

import ApiUtils from '../../helpers/APIUtils';
import store from '../store';

const api = new ApiUtils();
const { getState } = store;

export const getNotifications =
  ({ isBellRinging, page, limit }) =>
  async dispatch => {
    const { isHardRefresh } = getState().notification;
    if (isHardRefresh) {
      dispatch({
        type: 'HARD_REFRESH',
        isHardRefresh: false,
      });
    }

    const { data } = await api.getnotification({ page, limit });
    dispatch({
      type: 'NOTIFICATION',
      payload: data.data,
      isBellRinging,
      isHardRefresh,
    });
  };

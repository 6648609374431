const initalState = { cartItems: [] };
const CartReducer = (state = initalState, action) => {
  switch (action.type) {
    case 'CART_ADD_ITEM': {
      const item = action.payload;
      const existItem = state.cartItems.find(x => x._id === item._id);
      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map(items =>
            items._id === existItem._id
              ? {
                  ...items,
                  quantity: items.quantity + 1,
                }
              : items
          ),
        };
      }
      return {
        ...state,
        cartItems: [...state.cartItems, item],
      };
    }
    case 'CART_REMOVE_ITEM': {
      const item = action.payload;
      const existItem = state.cartItems.find(x => x._id === item._id);
      const cart = state.cartItems.map(items =>
        items._id === existItem._id
          ? {
              ...items,
              quantity: items.quantity - 1,
            }
          : items
      );
      return {
        ...state,
        cartItems: cart,
      };
    }
    case 'STORE_CART_DETAILS': {
      return {
        ...state,
        cartItems: action.payload,
      };
    }

    default:
      return state;
  }
};

export default CartReducer;

export const TRANSLATION_EN = {
  // login
  Lets_Connect_us: 'Let`s Connect us',
  Lorem_Ipsum_is_simply_dummy_text_of_the_printing_and_typesetting_industry:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
  Login: 'Login',
  Login_to_your_account: 'Login to your account',
  Enter_your_email_and_password_to_connect: 'Enter your email and password to connect',
  with_Jail_connect: 'with jail connect',
  Email_address: 'Email address',
  Password: 'Password',
  Remember_me: 'Remember me',
  Forgot_password: 'Forgot password',
  Please_enter_a_valid_email: 'Please enter a valid email',

  // forgot password
  Forgot_your_password: 'Forgot your password',
  Send: 'Send',
  Already_have_an_account: 'Already have an account ?',
  verification_link_will_be_send_to_your_email: 'Verification link will be send to your email',

  // reset password
  Reset_Password: 'Reset Password',
  New_password: 'New password',
  Confirm_New_password: 'Confirm New password',
  Reset: 'Reset',
  Reset_your_password: 'Reset your password',

  // dashboard
  Total_products: 'Total products',
  Total_earnings: 'Total earnings',
  Pending_orders: 'Pending orders',
  Total_orders: 'Total orders',

  // sidebar
  Dashboard: 'Dashboard',
  commissary: 'Commissary',
  Inmates: 'Inmates',
  Order_Management: 'Order Management',
  Category_Management: 'Category Management',
  Product_Management: 'Product Management',
  My_Profile: 'My Profile',
  Change_Password: 'Change Password',
  About_us: 'About us',
  Terms_Conditions: 'Terms & Conditions',
  Privacy_Policy: 'Privacy Policy',
  Logout: 'Logout',
  Profile: 'Profile',
  Notifications: 'Notifications',

  // edit - profile
  Edit_Profile: 'Edit Profile',
  First_Name: 'First Name',
  Last_Name: 'Last Name',
  Phone_Number: 'Phone Number',
  save: 'save',

  // change password
  Change_your_password: 'Change your password',
  Current_password: 'Current password',
  Change: 'Change',

  // Commissary
  Checkout: 'Checkout',
  Product_Name: 'Product Name',
  Action: 'Action',
  Updated_At: 'Updated At',
  Created_At: 'Created At',
  Price: 'Price',
  Category: 'Category',
  Image_Url: 'Image Url',
  Select_Category: 'Select Category',
  Search: 'Search',
  Add_to_cart: 'Add to cart',
  view: 'view',
  // notification
  Total_Count: 'Total Count',
  Total_inmates: 'Total inmates',

  //  modal
  My_Cart: 'My Cart',
  No_item_is_added: 'No item is added',
  Total_Price: 'Total Price',
  Total_Quantity: 'Total Quantity',
  View_Product: 'View Product',
  View_Kiosk: 'View Kiosk',
  Select_Inmate: 'Select Inmate',
  Search_here: 'Search here',
  Select_here: 'Select here',
  payment: 'payment',
  wallet: 'wallet',
  product_cart: 'Product Cart',
  Add_Credit_to_your_inmate_account: 'Add Credit to your inmate account',
  Enter_amount: 'Enter amount',
  Proceed_for_Payment: 'Procced for Payment',
  Select_payment_method: 'Select payment method',
  Standard_Plan: 'Standard Plan',
  Basic_Plan: 'Basic Plan',

  // Inmates
  Name: 'Name',
  Email: 'Email',
  Prison: 'Prison',
  add_to_wallet: 'Add to wallet',
  recharge: 'Recharge',
  communication_recharge: 'Communication recharge',
  credit_inmate: 'Credit inmate',
  Inmate_Password: 'Inmate Password',

  // view-content
  OK: 'OK',
};

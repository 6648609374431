import { getLiveNotification } from '../../firebase';
import ApiUtils from '../../helpers/APIUtils';
import store from '../store';

const api = new ApiUtils();

export const loadUser = fcm => async (dispatch, getState) => {
  localStorage.setItem('fcm', fcm);
  try {
    const token = localStorage.getItem('id_token');
    const languages = localStorage.getItem('i18nextLng');
    const data = {
      platform: 1,
      fcm: fcm === 'undefined' ? undefined : fcm,
      language: languages,
    };
    if (!token) return dispatch({ type: 'NEW_USER' });

    const res = await api.loadUser({}, { Authorization: `Bearer ${token}` }, data);

    dispatch({
      type: 'EXISTING_USER',
      payload: res.data,
      token,
    });

    dispatch({
      type: 'HARD_REFRESH',
      isHardRefresh: true,
    });

    getLiveNotification(dispatch, res.data.data._id, getState);

    return true;
  } catch (err) {
    dispatch({ type: 'AUTH_FAILED' });
    return false;
  }
};

export const login = (data, fcm) => async dispatch => {
  try {
    const res = await api.login(data);
    // after successfully login, you will get token on it
    localStorage.setItem('id_token', res?.data?.token);
    await dispatch(loadUser(fcm));
    return true;
  } catch (err) {
    dispatch({ type: 'AUTH_FAILED' });
    return false;
  }
};

export const logout = data => async dispatch => {
  try {
    const { auth } = store.getState();
    if (auth.token) {
      await api.logout(data);
      await dispatch({ type: 'LOGOUT' });
    }
    localStorage.removeItem('id_token');
    return true;
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-http-backend';
// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { TRANSLATION_EN } from './locals/en';
import { TRANSLATION_ES } from './locals/es';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: TRANSLATION_EN,
  },

  es: {
    translation: TRANSLATION_ES,
  },
};
const languagesInLocal =
  localStorage.getItem('i18nextLng') === null ? 'en' : localStorage.getItem('i18nextLng');
i18n
  // .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: languagesInLocal === 'en-GB' || languagesInLocal === 'en' ? 'en' : 'es',
    // lng:
    //   window.localStorage.i18nextLng === ('en-GB' || 'en-US')
    //     ? 'en'
    //     : window.localStorage.i18nextLng,
    // detection: options,
    resources,
    fallbackLng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    // interpolation: {
    //   escapeValue: false, // react already safes from xss
    // },
    // react: {
    //   wait: true,
    // },
  });

export default i18n;

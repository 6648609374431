import axios from 'axios';
import { notification } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import store from '../redux/store';
import { logout } from '../redux/actions/authActions';

class ApiUtils {
  constructor(message = false, request = true, appendAuth = true, response = true) {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_API_PATH,
    });
    if (request) {
      this.axios.interceptors.request.use(
        config => {
          const myConfig = { ...config };
          if (appendAuth) {
            const { auth } = store.getState();
            const language = localStorage.getItem('i18nextLng');
            if (auth.isAuthenticated) myConfig.headers.Authorization = `Bearer ${auth.token}`;
            myConfig.headers['Accept-Language'] = language;
            // myConfig.headers['Accept-Language'] = language;
          }
          return myConfig;
        },
        error => {
          console.debug('Request Error', error);
          console.debug('Request Failed', error.request.data.message);
          return Promise.reject(error);
        }
      );
    }

    if (response) {
      this.axios.interceptors.response.use(
        config => {
          const myConfig = { ...config };
          if (message) {
            notification.open({
              message: 'Success',
              description: myConfig.data.message,
            });
          }
          return myConfig;
        },
        error => {
          if (error.response.data.status === 401 || error.response.data.status === 403) {
            notification.open({
              message: 'Error',
              description: error.response.data.message,
            });
            store.dispatch(logout());
          } else {
            notification.open({
              message: 'Error',
              description: error.response.data.message,
            });
          }
          console.debug('Response Error', error);
          console.debug('Response Error', error.response.data.message);
          return Promise.reject(error);
        }
      );
    }
  }

  loadUser = (params, headers, data) =>
    this.axios({
      method: 'POST',
      url: '/profile/me',
      // FOR LOCAL url: '/api/users/:id' ( dont forget to change response in loadUser )
      params,
      data,
      headers,
    });

  login = data =>
    this.axios({
      method: 'POST',
      url: '/auth/login',
      // FOR LOCAL url: '/api/users/:id' ( dont forget to change response in loadUser )
      data,
    });

  logout = data =>
    this.axios({
      method: 'POST',
      url: '/profile/logout',
      data,
    });

  getTarif = urlId =>
    this.axios({
      method: 'GET',
      url: `payments/plans/${urlId}`,
    });

  getnotification = (params, data) =>
    this.axios({
      method: 'POST',
      url: '/notification/lists',
      data,
      params,
    });

  forgotPassword = data =>
    this.axios({
      method: 'POST',
      url: '/auth/forgot-password',
      data,
    });

  changeLanguages = data =>
    this.axios({
      method: 'PATCH',
      url: '/profile/update/language',
      data,
    });

  changePassword = data =>
    this.axios({
      method: 'PATCH',
      url: '/profile/change-password',
      data,
    });

  resetPassword = (data, token) =>
    this.axios({
      method: 'PATCH',
      url: `/auth/reset-password/${token}`,
      data,
    });

  getAboutUs = data =>
    this.axios({
      method: 'GET',
      url: '/static-page/about-us',
      data,
    });

  getPrivacyPolicy = data =>
    this.axios({
      method: 'GET',
      url: '/static-page/privacy-policy',
      data,
    });

  getTermsCondition = data =>
    this.axios({
      method: 'GET',
      url: '/static-page/terms-conditions',
      data,
    });

  getDashboard = data =>
    this.axios({
      method: 'GET',
      url: '/dashboard/lists',
      // FOR LOCAL url: '/api/users/:id' ( dont forget to change response in loadUser )
      data,
    });

  editProfile = data =>
    this.axios({
      method: 'PUT',
      url: '/profile/update',
      data,
    });

  getProducts = ({ params, data }) =>
    this.axios({
      method: 'POST',
      url: '/commissary/products',
      params,
      data,
    });

  placeOrderFromWallet = data =>
    this.axios({
      method: 'POST',
      url: '/orders/create',
      data,
    });

  getCategory = ({ params, data }) =>
    this.axios({
      method: 'POST',
      url: '/commissary/categories',
      data,
      params,
    });

  updateCart = data =>
    this.axios({
      method: 'PUT',
      url: '/cart/manage',
      data,
    });

  getCartList = data =>
    this.axios({
      method: 'GET',
      url: '/cart/lists',
      data,
    });

  createCheckoutSession = data =>
    this.axios({
      method: 'POST',
      url: '/payments/create/order',
      data,
    });

  RechargeInmate = data =>
    this.axios({
      method: 'POST',
      url: '/payments/create/recharge',
      data,
    });

  getInmateList = ({ params, data }) =>
    this.axios({
      method: 'POST',
      url: '/inmate/lists',
      data,
      params,
    });

  updateInmate = (data, id) =>
    this.axios({
      method: 'PUT',
      url: `/inmate/users/update/${id}`,
      data,
    });

  verifyUser = token =>
    this.axios({
      method: 'PATCH',
      url: `/auth/verify-user/${token}`,
    });
}

export default ApiUtils;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { collection, doc, getFirestore, onSnapshot } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { deleteToken, getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getNotifications } from './redux/actions/notificationActions';
// import store from './redux/store';

const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

const firebaseConfig = {
  apiKey: 'AIzaSyBBAA00UvxsTtVdpU616HX3UrbAcM1uHNM',
  authDomain: 'jail-connect.firebaseapp.com',
  projectId: 'jail-connect',
  storageBucket: 'jail-connect.appspot.com',
  messagingSenderId: '776206377742',
  appId: '1:776206377742:web:2615e41c14222d4c6eed5f',
  measurementId: 'G-9XCLBKXQWH',
};
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

const messaging = getMessaging();
export const requestForToken = async () => {
  let currentToken;
  try {
    currentToken = await getToken(messaging, vapidKey);
  } catch (err) {
    console.log(err);
  }
  return currentToken;
};
export const getLiveNotification = (dispatch, id, getState) => {
  onSnapshot(doc(db, 'live-notification', id), async () => {
    // console.debug('yoooooooo', docs);
    const { isHardRefresh, isBellRinging } = getState().notification;
    // const Refresh = isHardRefresh.notification.isHardRefresh;

    await dispatch(
      getNotifications({
        isBellRinging: !isHardRefresh,
        page: 1,
        limit: 10,
      })
    );
  });
};

export const onMessageListener = dispatch =>
  new Promise(resolve => {
    onMessage(messaging, async payload => {
      await dispatch(
        getNotifications({ isBellRinging: true, page: 1, limit: 2, isHardRefresh: false })
      );
      resolve(payload);
      // console.log('payload', payload);
    });
  });
export const deleteForToken = () => {
  deleteToken(messaging, {
    vapidKey:
      'BMk_SrLYdb0Snc11ZomPhEnqNP9Ljd0gmiM7ZhcFPK9ngUgXOEbTG893gJfuIVH0P_m3Mqn0eJ0DBy0ry6RB7X8',
  });
};

const initialState = {
  docs: [],
  limit: 10,
  page: 1,
  totalDocs: 0,
  isBellRinging: false,
  totalPages: 0,
  isHardRefresh: false,
  ispathname: false,
};

const NotificationReducer = (state = initialState, action) => {
  const { type, payload, isBellRinging, isHardRefresh } = action;
  switch (type) {
    case 'NOTIFICATION':
      return {
        ...state,
        ...payload,
        isBellRinging,
        // ...isHardRefresh,
      };
    case 'READ_NOTIFICATION':
      return {
        ...state,
        ...payload,
        ...isBellRinging,
      };
    case 'HARD_REFRESH':
      return {
        ...state,
        isHardRefresh,
      };

    default:
      return state;
  }
};

export default NotificationReducer;
